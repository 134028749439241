import styled from '@emotion/styled'
import { Flex } from 'driverama-core/components/Flex'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, weight } from 'driverama-core/styles/variables'
import { HeaderProps } from 'react-big-calendar'
import { CalendarOpeningHours, getOpeningHoursForDate } from '../Calendar.utils'

interface HeaderOuterProps extends HeaderProps {
  openingDays?: CalendarOpeningHours
}

export const SHeaderTitle = styled(TextBody)`
  font-weight: ${weight('light')};
`

export const SHeader = styled.div<{ closed?: boolean }>`
  background: ${({ closed }) =>
    closed ? color('night-text', 0.08) : color('night-l-700')};
  border-radius: ${radius('corner')};
  margin: 0 auto;
  padding: ${size(1)};
  width: ${size(30)};

  ${TextBody}, ${TextHeader}, ${SHeaderTitle} {
    color: ${({ closed }) =>
      closed ? color('night-text') : color('night-l-100')};
  }
`

export function Header(props: HeaderOuterProps) {
  const isOpen = getOpeningHoursForDate(props.openingDays ?? {}, props.date)

  return (
    <SHeader closed={!Boolean(isOpen)}>
      <Flex variant="column" justify="center" align="center">
        <SHeaderTitle size="small">
          {props.localizer.format(props.date, 'E', 'en-GB')}
        </SHeaderTitle>
        <TextHeader as="h5">
          {props.localizer.format(props.date, 'dd', 'en-GB')}
        </TextHeader>
      </Flex>
    </SHeader>
  )
}
