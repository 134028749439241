import { URLS } from 'constants/api'
import { OpportunityState } from 'driverama-core/api/driverama/opportunities/opportunityDetail'

export const QUERY_KEYS = {
  bpmResult: URLS.bpmCalculationsSearch,
  slotsSearch: (
    branchId: string,
    from: string,
    to: string,
    filters: string[],
    employeeIds: string[],
    opportunityStates: OpportunityState[]
  ) => [
    URLS.slotsSearch(branchId),
    { from, to, filters, employeeIds, opportunityStates }
  ],
  lovBranchesSearch: (args?: Record<string, unknown>) => [
    URLS.lovBranchesSearch,
    args
  ],
  customersSearch: (args?: Record<string, unknown>) => [
    URLS.customersSearch,
    args
  ],
  employeesSearch: (args?: Record<string, unknown>) => [
    URLS.employeesSearch,
    args
  ],
  opportunitiesSearch: (args?: Record<string, unknown>) => [
    URLS.opportunitiesSearch,
    args
  ],
  opportunitiesSellingSearch: (args?: Record<string, unknown>) => [
    URLS.opportunitiesSellingSearch,
    args
  ],
  customerDetail: (customerId?: string, args?: Record<string, unknown>) => [
    customerId,
    URLS.opportunitiesSearch,
    args
  ],
  getAdminOpportunityDetail: (opportunityId?: string) => [
    URLS.opportunitiesSearch,
    opportunityId
  ],
  opportunityDetail: (
    opportunityId?: string,
    args?: Record<string, unknown>
  ) => [URLS.opportunitiesSearch, opportunityId, args],
  noteSearch: (args?: Record<string, unknown>) => [
    URLS.opportunityNotesSearch,
    args
  ],
  orderNoteSearch: (args?: Record<string, unknown>) => [URLS.orderNotes, args],
  ordersSearch: (args?: Record<string, unknown>) => [URLS.ordersSearch, args],
  carsSearch: (args?: Record<string, unknown>) => [URLS.carSearch, args],
  orderDetail: (orderId?: string) => URLS.orderDetail(orderId),
  bookingUnavailability: (bookingUnavailabilityId: string) =>
    URLS.bookingUnavailability(bookingUnavailabilityId),
  dailyReport: (branchId?: string) => [URLS.dailyReport, branchId]
}
