import { Button } from 'driverama-core/components/button/Button'
import { Flex } from 'driverama-core/components/Flex'
import { useCloseModalContext } from 'driverama-core/components/modal/Modal.utils'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { toast } from 'driverama-core/components/toast/Toast'
import { useTranslation } from 'react-i18next'
import { useBookingUnavailabilityDeleteMutation } from '../../../api/driverama/appointments/unavailability'

interface DisabledBuyoutDeliveryDeleteModalProps {
  id: string
  onConfirm: () => Promise<void>
}

export function DisabledBuyoutDeleteModal(
  props: DisabledBuyoutDeliveryDeleteModalProps
) {
  const { t } = useTranslation(['core', 'appointment'])
  const closeModal = useCloseModalContext()

  const deleteBookingUnavailability = useBookingUnavailabilityDeleteMutation(
    props.id
  )

  async function handleDeleteBookingUnavailability() {
    try {
      await deleteBookingUnavailability.mutateAsync()
      await props.onConfirm()
      closeModal()
    } catch (error) {
      toast({ content: t('core:error_common'), type: 'error', error })
    }
  }

  return (
    <>
      <TextHeader as="h3">
        {t('appointment:disabled_buyout_delete_confirmation_title')}
      </TextHeader>

      <Spacer size={4} />

      <TextBody>
        {t('appointment:disabled_buyout_delete_confirmation_text')}
      </TextBody>

      <Spacer size={8} />

      <Flex variant="row" gap={4}>
        <Button variant="outline" onClick={closeModal}>
          {t('core:cancel')}
        </Button>
        <Button
          variant="primary"
          css={{ width: '100%' }}
          disabled={deleteBookingUnavailability.isLoading}
          onClick={handleDeleteBookingUnavailability}
        >
          {t('core:confirm')}
        </Button>
      </Flex>
    </>
  )
}
