import { addHours, format, getDay, isAfter, parse, startOfWeek } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'
import { dateFnsLocalizer } from 'react-big-calendar'

const locales = {
  'en-GB': enGB
}

function getEventEndTime(start: Date, end: Date) {
  const minEnd = addHours(start, 1)
  if (isAfter(end, minEnd)) {
    return end
  }
  return minEnd
}

export const localizer = dateFnsLocalizer({
  format,
  parse,
  //https://github.com/jquense/react-big-calendar/issues/2070
  startOfWeek: (date: Date) => startOfWeek(date, { weekStartsOn: 1 }),
  getDay,
  locales
})

// FIX: minimum one hour event - I really hate it
localizer.formats.selectRangeFormat = ({ start, end }) =>
  `${format(start, 'p')} - ${format(getEventEndTime(start, end), 'p')}`
