import { css } from '@emotion/react'
import { endOfWeek, format, startOfWeek } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'
import { Flex } from 'driverama-core/components/Flex'
import { useModal } from 'driverama-core/components/modal/Modal'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import IconPin from 'driverama-core/images/icons/IconPin.svg'
import { color } from 'driverama-core/styles/variables'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useBranch } from '../../../api/driverama/booking/branches'
import { getBookingSlotsFilters } from '../../../api/driverama/booking/slots'
import { LINKS } from '../../../constants/links'
import { QUERY_KEYS } from '../../../constants/queryKeys'
import { useWizard } from '../../../sections/wizard/WizardContext'
import { Loading } from '../../wizard/loading/Loading'
import { mapDaysToDates } from '../Calendar.utils'
import { getBranchType } from '../event/Event'
import { DisabledHomeDeliveryDeleteModal } from './DisabledHomeDeliveryDeleteModal'
import { PopupProps } from './Popup'
import { PopupHeader } from './PopupHeader'
import { PopupListItem } from './PopupListItem'

export function DisabledHomeDeliveryEvent({
  event,
  branchId,
  onClose
}: PopupProps) {
  const branchQuery = useBranch(branchId)
  const { t } = useTranslation(['core', 'appointment'])
  const queryClient = useQueryClient()
  const { state } = useWizard()

  const [modal, openModal] = useModal(
    () => (
      <DisabledHomeDeliveryDeleteModal
        id={event.id}
        onConfirm={async () => {
          const calendarStart = startOfWeek(
            event.start ?? new Date(),
            enGB.options
          )
          const calendarEnd = endOfWeek(event.start ?? new Date(), enGB.options)

          await queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.slotsSearch(
              branchId,
              format(calendarStart, 'yyyy-MM-dd'),
              format(calendarEnd, 'yyyy-MM-dd'),
              getBookingSlotsFilters(state.calendarFilters),
              state.employeeFilters,
              // TODO! add daily report filters
              []
            )
          })
        }}
      />
    ),
    {
      closeLabel: t('core:close')
    }
  )

  function renderContent() {
    if (branchQuery?.isLoading) {
      return <Loading />
    }

    if (branchQuery?.branch) {
      const branchType = getBranchType(t, [branchQuery.branch], branchId)

      const openingHours = Object.values(
        mapDaysToDates(
          event.start ?? new Date(),
          event.end ?? new Date(),
          branchQuery?.branch.openingHours
        )
      )

      return (
        <>
          {openingHours && (
            <TextBody
              variant="body"
              size="large"
              css={css`
                color: ${color('black')};
              `}
            >
              {format(openingHours[0].from, 'HH:mm')} -{' '}
              {format(openingHours[0].to, 'HH:mm')}
            </TextBody>
          )}
          <Spacer size={4} />
          <Flex variant="column" gap={2}>
            <PopupListItem Icon={IconPin}>
              {branchQuery.branch.fullAddress}
            </PopupListItem>
            <PopupListItem Icon={branchType.icon}>
              {branchType.label}
            </PopupListItem>
          </Flex>
          <Spacer size={4} />
        </>
      )
    }

    return null
  }

  return (
    <>
      {modal}
      <PopupHeader
        onClose={onClose}
        editLink={LINKS.disable_homedelivery(branchId, event.id)}
        handleDelete={openModal}
      />
      <Spacer size={2} />
      <TextHeader variant="h5">
        {t('appointment:disabled_home_delivery')}
      </TextHeader>

      {renderContent()}
    </>
  )
}
