import { CSSProperties, forwardRef } from 'react'
import {
  CustomRBEvent,
  isDisabledBuyout,
  isDisabledHomeDelivery,
  isOpportunity,
  isOrder,
  isReclamation
} from '../../../api/driverama/booking/slots'
import { DisabledBuyoutEvent } from './DisabledBuyoutEvent'
import { DisabledHomeDeliveryEvent } from './DisabledHomeDeliveryEvent'
import { OpportunityEvent } from './opportunity/OpportunityEvent'
import { OrderEvent } from './OrderEvent'
import { SPopup } from './Popup.styled'

export interface PopupProps {
  style: CSSProperties
  event: CustomRBEvent
  branchId: string
  onClose: () => void
  onChangeEvent: (values: Partial<CustomRBEvent>) => void
}

export const Popup = forwardRef<HTMLDivElement, PopupProps>(function (
  { event, branchId, ...props },
  ref
) {
  return (
    <SPopup ref={ref} {...props}>
      {isOpportunity(event.type) && (
        <OpportunityEvent event={event} branchId={branchId} {...props} />
      )}
      {(isOrder(event.type) || isReclamation(event.type)) && (
        <OrderEvent event={event} branchId={branchId} {...props} />
      )}

      {isDisabledHomeDelivery(event.type) && (
        <DisabledHomeDeliveryEvent
          event={event}
          branchId={branchId}
          {...props}
        />
      )}

      {isDisabledBuyout(event.type) && (
        <DisabledBuyoutEvent event={event} branchId={branchId} {...props} />
      )}
    </SPopup>
  )
})
