import { size } from 'driverama-core/styles/spacing'
import { isNumber } from 'driverama-core/utils/types'
import IconPhone from 'images/IconPhone.svg'
import IconPhoneOff from 'images/IconPhoneOff.svg'
import {
  CustomRBEvent,
  isDisabledBuyout,
  isDisabledHomeDelivery,
  isOpportunity,
  isOrder,
  isReclamation
} from '../../../api/driverama/booking/slots'

export function getEventTypeClassName(
  eventType: CustomRBEvent['type'],
  event: CustomRBEvent
) {
  if (isOpportunity(eventType)) {
    const appointmentType = event.appointment
      ? `__${event.appointment?.location.toLowerCase()}`
      : undefined
    return ['__opportunity', appointmentType]
  }

  if (isOrder(eventType)) {
    const deliveryType = `__${
      event.orderAppointment?.branchId ? 'BRANCH' : 'HOME'
    }`

    return ['__handover', deliveryType.toLowerCase()]
  }

  if (isReclamation(eventType)) {
    return ['__reclamation']
  }

  if (isDisabledHomeDelivery(eventType)) {
    return ['__disabled_home_delivery', '__home']
  }

  if (isDisabledBuyout(eventType)) {
    return ['__disabled_buyout']
  }

  return []
}

export function renderB2bCalledIcon(b2bCalled: boolean) {
  if (b2bCalled) {
    return <IconPhone />
  }

  return <IconPhoneOff />
}

const SPACING = size(2, false)

export function calculatePopperTopPlacement(
  popperTopOffsetPercentage?: string | number,
  popper?: HTMLDivElement | null
) {
  if (!popperTopOffsetPercentage || !popper) {
    return 0
  }

  if (isNumber(popperTopOffsetPercentage)) {
    // derive time content height from popper offsetParent property
    const calendarTimeLineHeight = popper.offsetParent?.clientHeight

    if (calendarTimeLineHeight) {
      // calculate absolute popperTopOffset
      const popperTopOffset =
        (calendarTimeLineHeight * popperTopOffsetPercentage) / 100
      // actual popper height
      const popperHeight = popper.clientHeight

      // check for overflow
      const isPopperOverflowing =
        popperTopOffset + popperHeight > calendarTimeLineHeight

      if (isPopperOverflowing) {
        // calculate delta
        const delta =
          popperTopOffset + popperHeight + SPACING - calendarTimeLineHeight

        // move dialog "up" by delta and calculate percentage
        return ((popperTopOffset - delta) / calendarTimeLineHeight) * 100
      }
    }
  }

  return popperTopOffsetPercentage
}
